@use '../config/' as *;
.waf-membersdetail {
    @extend %page-head-blue-bg;
    @extend %mx-2-neg;
    &.waf-component {
        @extend %mb-0;
        @extend %p-0;
    }
    .sub-title {
        @extend %body-mb;
        @extend %neutral-0;
        @extend %mb-0;
    }
    .waf-body {
        margin-top: var(--space-18-neg);
        @extend %bg-transparent;
        @extend %mb-8;
    }
    .data {
        @extend %title-50;
    }
    .text {
        @extend %body-l;
    }
    @each $social-icon, $social-value in $social {
        .icon-#{$social-icon} {
            &::before {
                content: unset;
            }
            @extend %flex;
            .social-link {
                &::before {
                    @extend %neutral-70;
                    @include icon(#{$social-icon}, 24);
                }
            }
        }
    }
    .social {
        &-item {
            width: 4rem;
            aspect-ratio: 1/1;
            @extend %flex-c-c;
        }
    }
    .icon-twitter {
        .social-link {
            &::before {
                font-size: 2rem;
            }
        }
    }
    .documents-title {
        @extend %title-50;
        @extend %mb-0;
    }
    .card-list {
        @extend %mt-8;
    }
    .member {
        &-info,
        &-details {
            @extend %p-6-4;
            @extend %bg-neutral-0;
            @extend %border-radius-m;
        }
        &-thumbnail {
            aspect-ratio: 1/1;
            max-height: 31.2rem;
            @extend %mx-auto;
            img {
                object-fit: cover;
                @extend %border-radius-s;
                @extend %w-100;
                @extend %h-100;
            }
        }
        &-data {
            @extend %mt-4;
        }
        &-social {
            @extend %flex-n-c;
            @extend %gap-2;
            @extend %mt-4;
            .text {
                @extend %font-0;
            }
        }
        &-documents {
            @extend %mt-6;
            @extend %pt-6;
            @extend %event-card-small;
            @include border(1, neutral-20, 10, top);
            .card-item {
                min-height: 8.2rem;
                &::before {
                    opacity: 1;
                }
            }
            .title {
                font-size: 1.4rem;
            }
            .btn-link::before {
                content: "\e84d";
            }
        }
        &-details {
            @extend %mt-8;
        }
    }
    .detail {
        &s {
            + .details {
                @extend %mt-10;
            }
        }
        &-title {
            @extend %title-50;
            @extend %mb-6;
        }
        &-list {
            list-style: disc;
            @extend %pl-5;
            li {
                @extend %body-l;
            }
        }
        &-wrap {
            p,
            .text {
                font-size: 1.6rem;
                margin-bottom: 3rem;
                &:empty {
                    @extend %d-none;
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-membersdetail {
        .waf-body {
            display: grid;
            grid-template-columns: 45.1rem calc(100% - 47.5rem);
            margin-bottom: var(--space-20);
            gap: var(--space-6);
        }
        .data,
        .documents-title {
            font-size: 2.4rem;
        }
        .card {
            &-list {
                margin-top: var(--space-10);
            }
            &-item {
                min-height: 6.4rem;
            }
        }
        .member {
            &-info,
            &-details {
                padding: var(--space-6);
                height: max-content;
            }
            &-details {
                border-radius: 2.4rem;
                padding: var(--space-12);
                margin-top: 0;
            }
            &-thumbnail {
                aspect-ratio: 1/1;
                max-height: 40.3rem;
            }
            &-documents {
                margin-top: var(--space-10);
                padding-top: var(--space-10);
                .card-list {
                    flex-direction: column;
                    > * {
                        width: 100%;
                    }
                }
            }
        }
        .detail {
            &s {
                display: flex;
                gap: var(--space-6);
                + .details {
                    margin-top: var(--space-20);
                }
            }
            &-title {
                font-size: 2.4rem;
                margin-bottom: 0;
                width: 14.5rem;
            }
            &-wrap {
                width: calc(100% - 16.9rem);
            }
        }
    }
}